import { createStore, compose, applyMiddleware } from 'redux';
import reducers from './reducers';
import { createLogger } from 'redux-logger';

// preloadedState will be passed in by the plugin
export default (preloadedState) => {
	const logger = createLogger({
		collapsed: true,
		timestamp: true,
		duration: true
	});

	return createStore(reducers, preloadedState, compose(applyMiddleware(logger)));
};
