import * as actions from './action-types';

export const initialState = {
	userData: null
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actions.SAVE_USER:
			return { ...state, userData: action.payload };
		case actions.DELETE_USER:
			return { ...state, userData: null };
		default:
			return state;
	}
}
