import * as actions from './action-types';

export const initialState = {
	boxesData: null,
	batch: ''
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actions.SAVE_BOXES:
			return { ...state, boxesData: action.payload.boxes, batch: action.payload.batch };
		default:
			return state;
	}
}
