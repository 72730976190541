import * as actions from './action-types';

export const initialState = {
	subscriptionsData: null
};

export default function (state = initialState, action) {
	switch (action.type) {
		case actions.SAVE_SUBSCRIPTIONS:
			return { ...state, subscriptionsData: action.payload };
		default:
			return state;
	}
}
