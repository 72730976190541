import { combineReducers } from 'redux';
import userReducer from './user';
import boxesReducer from './boxes';
import subscriptionsReducer from './subscriptions';
import couponsReducer from './coupons';

export default combineReducers({
	user: userReducer,
	boxes: boxesReducer,
	subscriptions: subscriptionsReducer,
	coupons: couponsReducer
});
